import Vue from 'vue';

import axios from 'axios';
Vue.mixin({
    computed: {
        isTeacher() {
            return this.$store.state.info.memberInfo.role === 'organization_teacher' || this.$store.state.info.memberInfo.role === 'organization_admin'
        },
        isManage() {
            return this.$store.state.info.memberInfo.role === 'organization_admin'
        }
    },
    methods: {
        //上传promise
        getUploadPromise(item, ossUploadData) {
            return new Promise(resolve => {
                let param = new FormData(),
                    fileName = ossUploadData.fileName + item.file.name // 创建form对象
                param.append('key', ossUploadData.path + fileName) //存储在oss的文件路径
                param.append('OSSAccessKeyId', ossUploadData.accessid) //accessKeyId
                param.append('policy', ossUploadData.policy) //policy
                param.append('Signature', ossUploadData.signature) //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                param.append('file', item.file)
                param.append('success_action_status', 200) //成功后返回的操作码
                axios.post(ossUploadData.host, param, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    resolve({
                        addr: ossUploadData.path + fileName,
                        info: item.info,
                    })
                })
            })
        }
    }
});
