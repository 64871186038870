import Vue from 'vue';

var filter = {
    classRange: function (value) {
        if (value == "") return "";
        if (value.length <= 2) {
            return value.join(",");
        } else {
            return value[0] + "," + value[1] + "...";
        }
    },
    imgFormat: function (src, isbg) {
        let res = '';
        if (!src) return;

        if (src.indexOf('http') != -1) {
            if (src.indexOf('thirdwx') == -1) {
                res = src.replace(src.split('//')[1].split('/')[0], 'lvshuhualv.oss-cn-hangzhou.aliyuncs.com');
            } else {
                res = src;
            }
        } else {
            res = 'https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/' + src;
        }

        if (src.indexOf('thirdwx.qlogo.cn') != -1) {
            res = src; //第三方微信头像
        }

        if (isbg) {
            return 'background-image: url(' + res + ')';
        }
        return res;
    },
    formatDate (date, fmt) {
        if (date == '' || date == null) {
            return '';
        }
        date = new Date(date);
        if (typeof fmt === 'undefined') {
            fmt = 'yyyy-MM-dd HH:mm:ss';
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'H+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        };
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + '';
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
            }
        }
        return fmt;
    }
};
for (var filteritem in filter) {
    Vue.filter(filteritem, filter[filteritem]);
}
