import axios from 'axios';
import { Loading, Message } from 'element-ui';
import { delCookie } from '@/plugins/utils';

let base = 'https://api.lvshuhualv.com';
let isDev = process.env.NODE_ENV === 'development';

if (isDev) {
    // base = "https://ikuai0.haloom.cc:7991";
}

let ajax = axios.create({
    baseURL: base,
    timeout: 60000,
    withCredentials: true,
});

if (isDev) {
    // ajax.post(base + "/user/user/setCookie", "822cda06-bef0-4a44-ad6b-efa27f1310a8", {
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     withCredentials: true,
    // });
    // "ba21779e-40ac-41f4-bdfa-ca2a298a95ea"// 无权限
    // "822cda06-bef0-4a44-ad6b-efa27f1310a8" // yh 老师
    // "f2ee1b74-45bc-49e1-bd15-4acf97ffc54d"// 学生
}

let cancelToken = axios.CancelToken;
// 添加请求拦截器
ajax.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么

        config.cancelToken = new cancelToken(c => {
            //回调回去取消方法
            if (config.params) {
                config.params.abort && config.params.abort(c);
            } else if (config.abort) {
                config.abort(c);
            }
        });
        if (config.params && config.params.abort) {
            delete config.params.abort;
        } else if (config.abort) {
            delete config.abort;
        }

        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
ajax.interceptors.response.use(
    res => {
        console.log(res);
        // 对响应数据做点什么
        const code = res.data.code || 200;
        if (code === 401) {
            localStorage.removeItem('access_token');
            delCookie('flower_info');
            console.log(window.location.host);
            window.location.href = '/#/login';
            Message({
                type: 'error',
                message: res.data.msg,
            });
        } else if (code !== 200) {
            if (code === 403) {
                return Promise.reject(res.data);
            }
            if (code == 2001) {
                return res.data;
            }
            Message({
                type: 'error',
                message: res.data.msg,
            });
            return Promise.reject(res.data);
        } else {
            return res.data;
        }
    },
    error => {
        // 对响应错误做点什么
        let { message, code } = error;
        console.log(message);
        console.log('err' + error);

        if (message == 'Network Error') {
            message = '接口连接异常';
        } else if (message.includes('timeout') && code == 'ECONNABORTED') {
            message = '接口请求超时';
        } else if (message == 'Request failed with status code 403') {
            return Promise.reject(error);
        } else if (message.includes('Request failed with status code')) {
            message = '接口' + message.substr(message.length - 3) + '异常';
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);
const install = function (Vue) {
    Vue.prototype.$ajax = ajax;
    Vue.prototype.$axios = axios;
    Vue.prototype.$editAddr = 'https://plat.lvshuhualv.com/edit/#/';
};

export default {
    install,
};
